export enum ButtonSize {
    xsmall = 'xsmall',
    small = 'small',
    medium = 'medium',
    large = 'large'
}

export enum ButtonVariant {
    primary = 'primary',
    secondary = 'secondary',
    neutral = 'neutral',
    text = 'text'
}

export enum ButtonIcon {
    user = 'user',
    google = 'google',
    azure = 'azure',
    filter = 'filter',
    lock = 'lock',
    trash = 'trash',
    more = 'more',
    unlink = 'unlink',
    backpack = 'backpack',
    student = 'student',
    copy = 'copy',
    success = 'success',
    edit = 'edit',
    set = 'set',
    down = 'down',
    up = 'up',
    drivr = 'drivr',
    blob = 'blob',
    boolean = 'boolean',
    date = 'date',
    document = 'document',
    float = 'float',
    image = 'image',
    int = 'int',
    json = 'json',
    select = 'select',
    string = 'string',
    time = 'time',
    timespan = 'timespan',
    timestamp = 'timestamp',
    uuid = 'uuid',
    openId = 'open-id'
}

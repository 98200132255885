import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_p_v_input_text = _resolveComponent("p-v-input-text")!

  return (_openBlock(), _createBlock(_component_p_v_input_text, {
    id: _ctx.id,
    class: _normalizeClass({ 'p-inputtext--small': _ctx.small }),
    type: _ctx.type,
    disabled: _ctx.disabled,
    autocomplete: _ctx.autocomplete,
    name: _ctx.name,
    required: _ctx.required,
    "model-value": _ctx.modelValue,
    placeholder: _ctx.placeholder || _ctx.label,
    autofocus: _ctx.autofocus,
    "onUpdate:modelValue": _ctx.onUpdate
  }, null, 8, ["id", "class", "type", "disabled", "autocomplete", "name", "required", "model-value", "placeholder", "autofocus", "onUpdate:modelValue"]))
}
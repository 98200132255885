import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_page = _resolveComponent("default-page")!
  const _component_forget_form_page = _resolveComponent("forget-form-page")!
  const _component_forget_sent = _resolveComponent("forget-sent")!
  const _component_reset_form_page = _resolveComponent("reset-form-page")!
  const _component_reset_success_page = _resolveComponent("reset-success-page")!
  const _component_ui_unauth_layout = _resolveComponent("ui-unauth-layout")!

  return (_openBlock(), _createBlock(_component_ui_unauth_layout, { class: "drivr-auth-ui" }, {
    default: _withCtx(() => [
      (_ctx.page === 'default')
        ? (_openBlock(), _createBlock(_component_default_page, {
            key: 0,
            "forget-callback": _ctx.defaultPageForgetButtonCb
          }, null, 8, ["forget-callback"]))
        : _createCommentVNode("", true),
      (_ctx.page === 'forget-form')
        ? (_openBlock(), _createBlock(_component_forget_form_page, {
            key: 1,
            onBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.page = 'default')),
            onSuccess: _cache[1] || (_cache[1] = ($event: any) => (_ctx.page = 'forget-sent'))
          }))
        : _createCommentVNode("", true),
      (_ctx.page === 'forget-sent')
        ? (_openBlock(), _createBlock(_component_forget_sent, {
            key: 2,
            onBack: _cache[2] || (_cache[2] = ($event: any) => (_ctx.page = 'default'))
          }))
        : _createCommentVNode("", true),
      (_ctx.page === 'reset-form')
        ? (_openBlock(), _createBlock(_component_reset_form_page, {
            key: 3,
            token: _ctx.token,
            onBack: _cache[3] || (_cache[3] = ($event: any) => (_ctx.page = 'default')),
            onSuccess: _cache[4] || (_cache[4] = ($event: any) => (_ctx.page = 'reset-success'))
          }, null, 8, ["token"]))
        : _createCommentVNode("", true),
      (_ctx.page === 'reset-success')
        ? (_openBlock(), _createBlock(_component_reset_success_page, {
            key: 4,
            onBack: _cache[5] || (_cache[5] = ($event: any) => (_ctx.page = 'default'))
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}

    import { defineComponent } from 'vue';
    import UiUnauthLayout from '@/layouts/unauth/Unauth.vue';
    import DefaultPage from '@/pages/default.vue';
    import ForgetFormPage from '@/pages/forget-form.vue';
    import ForgetSent from '@/pages/forget-sent.vue';
    import ResetFormPage from '@/pages/reset-form.vue';
    import ResetSuccessPage from '@/pages/reset-success.vue';

    export default defineComponent({
        name: 'App',
        components: {
            UiUnauthLayout,
            DefaultPage,
            ForgetFormPage,
            ForgetSent,
            ResetFormPage,
            ResetSuccessPage
        },
        data (): {
            page: 'default' | 'forget-form' | 'forget-sent' | 'reset-form' | 'reset-success',
            token: string | null
        } {
            let token: string | null = null;
            const query = location.search.substr(1);
            query.split('&').forEach(part => {
                const i = part.split('=', 2);
                if (i[0] === 'token') {
                    token = decodeURIComponent(i[1]);
                }
            });

            if (location.pathname === '/password-reset' && token) {
                return {
                    page: 'reset-form',
                    token
                };
            }

            return {
                page: 'default',
                token: null
            };
        },
        methods: {
            async defaultPageForgetButtonCb () {
                this.page = 'forget-form';
            }
        }
    });

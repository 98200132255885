
    import { defineComponent } from 'vue';
    import UiUnauthBg from '../../components/unauth-bg/UnauthBg.vue';

    export default defineComponent({
        name: 'UnauthLayout',

        components: {
            UiUnauthBg
        },

        props: {
            footer: {
                type: String,
                default: `© ${new Date().getFullYear()} XCNT GmbH`,
                required: false
            }
        }
    });


    import { defineComponent, PropType } from 'vue';
    import UiInput from '@/components/input/Input.vue';
    import UiButton from '@/components/button/Button.vue';
    import axios from 'axios';

    const localStorageUsernameKey = 'io.xcnt.drivr.components.login.username';

    export default defineComponent({
        name: 'LoginPage',

        components: {
            UiButton,
            UiInput
        },

        props: {
            forgetCallback: {
                type: Function as PropType<() => Promise<void> | void>,
                default: null
            }
        },
        data (): {
            welcome: string,
            welcomeMultipleMethods: string,
            message: string | null,
            username: string | null,
            password: string | null,
            loading: 'google' | 'azure' | 'form' | 'forgot' | null,
            showLogin: boolean,
            animateLogin: boolean,
            errorMessage: string | null,
            formUrl: string | null,
            googleUrl: string | null,
            azureUrl: string | null
        } {
            let username = '';
            let errorMessage = null;
            const params = new URLSearchParams(window.location.search);

            try {
                username = localStorage[localStorageUsernameKey] || '';
            } catch (error) {
                // @todo Report ths to Sentry
                // eslint-disable-next-line no-console
                console.debug('Unable to fetch username from local storage:', error);
            }

            if (params.get('error_code') === 'invalid_credentials') {
                errorMessage = 'The username or password you entered is not correct. ';
                errorMessage += 'Please check the username and re-enter the password to try again.';
            } else if (params.get('error_description') || params.get('message')) {
                // @see https://github.com/xcnt/drivr-auth-ui/pull/3#discussion_r562489452
                errorMessage = params.get('error_description') || params.get('message');
            } else if (params.get('error_code')) {
                errorMessage = `Unfortunately something went wrong, please try again later (error code: ${params.get('error_code')})`;
            }

            return {
                welcome: 'Welcome! Please connect with your ',
                welcomeMultipleMethods: 'Welcome! How would you like to connect?',
                message: null,
                username,
                password: '',
                loading: null,
                showLogin: false,
                animateLogin: false,
                errorMessage,
                formUrl: null,
                googleUrl: null,
                azureUrl: null
            };
        },

        computed: {
            computedWelcome: function () {
                const methods = [this.azureUrl, this.googleUrl, this.formUrl];
                if (methods.filter(Boolean).length > 1) {
                    return this.welcomeMultipleMethods;
                } else {
                    const methodName = this.azureUrl ? 'Microsoft' : this.googleUrl ? 'Google' : 'DRIVR';
                    return this.welcome + methodName + ' Account.';
                }
            }
        },

        created () {
            this.update();
            setTimeout(() => {
                this.animateLogin = false;
            }, 50);

            if (this.errorMessage) {
                setTimeout(() => {
                    this.errorMessage = null;
                }, 10000);
            }
        },
        methods: {
            update (): void {
                this.asyncUpdate().catch(error => {
                    this.showLogin = false;
                    alert(error);
                });
            },
            async asyncUpdate () {
                const status = await this.getStatus();
                const methods = status.authentication_methods;
                if (!Array.isArray(methods) || methods.length === 0) {
                    throw new Error('Unable to initialize login: No authentication method configured.');
                }

                this.formUrl = methods.includes('DEFAULT')
                    ? (location.origin + '/authenticate/authorize/default' + location.search)
                    : null;

                this.googleUrl = methods.includes('GOOGLE')
                    ? (location.origin + '/authenticate/google' + location.search)
                    : null;

                this.azureUrl = methods.includes('AZURE_AD')
                    ? (location.origin + '/authenticate/azure-ad' + location.search)
                    : null;

                this.showLogin = Boolean(this.formUrl || this.googleUrl || this.azureUrl);
            },
            async getStatus () {
                if (location.hostname === 'localhost') {
                    return { authentication_methods: ['DEFAULT', 'GOOGLE'] };
                }

                const response = await axios.get('/domain/status');
                if (response.status !== 200) {
                    throw new Error('Unable to get domain status: ' + response.status + ' ' + response.statusText);
                }

                return response.data;
            },
            initializeGoogleAuth (): void {
                this.loading = 'google';
                location.href = this.googleUrl ? this.googleUrl : '';
            },
            initializeAzureAuth (): void {
                this.loading = 'azure';
                location.href = this.azureUrl ? this.azureUrl : '';
            },
            initializeForgotPassword (): void {
                if (!this.forgetCallback) {
                    return;
                }

                const result = this.forgetCallback();
                if (result instanceof Promise) {
                    this.loading = 'forgot';
                    result.then(() => {
                        this.loading = null;
                    });
                }
            },
            updateUsername (username: string): void {
                try {
                    localStorage[localStorageUsernameKey] = username;
                } catch (error) {
                    // @todo Report this to Sentry
                    // eslint-disable-next-line no-console
                    console.debug('Unable to update username in storage:', error);
                }
            },
            onSubmit (): void {
                setTimeout(() => {
                    this.loading = 'form';
                }, 0);
            }
        }
    });


    import { defineComponent } from 'vue';
    import 'particles.js';

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import variables from '../../style/variables.module.scss';

    export default defineComponent({
        name: 'UiUnauthBg',

        mounted (): void {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.particlesJS('drivr-unauth-bg__particles', {
                particles: {
                    number: {
                        value: 100,
                        density: {
                            enable: true,
                            value_area: 800
                        }
                    },
                    color: {
                        value: variables.unauthBackgroundParticleColor
                    },
                    shape: {
                        type: 'circle',
                        stroke: {
                            width: parseInt(variables.unauthBackgroundParticleSize, 10),
                            color: variables.unauthBackgroundParticleBorderColor
                        }
                    },
                    opacity: {
                        value: 1,
                        random: false,
                        anim: {
                            enable: false
                        }
                    },
                    size: {
                        value: 6,
                        random: true,
                        anim: {
                            enable: false
                        }
                    },
                    line_linked: {
                        enable: true,
                        distance: 150,
                        color: variables.unauthBackgroundLinkColor,
                        opacity: 0.2,
                        width: 1
                    },
                    move: {
                        enable: true,
                        speed: 0.2,
                        direction: 'none',
                        random: false,
                        straight: false,
                        out_mode: 'out',
                        bounce: false,
                        attract: {
                            enable: false
                        }
                    }
                },
                interactivity: {
                    detect_on: 'canvas',
                    events: {
                        onhover: {
                            enable: false
                        },
                        onclick: {
                            enable: true,
                            mode: 'push'
                        },
                        resize: true
                    },
                    modes: {
                        push: {
                            particles_nb: 4
                        }
                    }
                },
                retina_detect: true
            });
        },

        beforeUnmount (): void {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (Array.isArray(window.pJSDom) && window.pJSDom.length > 0) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.pJSDom[0].pJS.fn.vendors.destroypJS();

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.pJSDom = [];
            }
        }
    });


    import { defineComponent, PropType } from 'vue';
    import PVButton from 'primevue/button';
    import { ButtonIcon, ButtonSize, ButtonVariant } from './types';

    export default defineComponent({
        name: 'UiButton',

        components: {
            PVButton
        },

        props: {
            label: {
                type: String,
                default: undefined
            },
            variant: {
                type: String as PropType<ButtonVariant>,
                default: ButtonVariant.neutral,
                validator: function (value: ButtonVariant): boolean {
                    return Object.values(ButtonVariant).includes(value);
                }
            },
            disabled: {
                type: Boolean,
                default: false
            },
            size: {
                type: String as PropType<ButtonSize>,
                default: ButtonSize.medium,
                validator: function (value: ButtonSize): boolean {
                    return Object.values(ButtonSize).includes(value);
                }
            },
            icon: {
                type: String as PropType<ButtonIcon | undefined>,
                default: undefined,
                validator: function (value: ButtonIcon): boolean {
                    return Object.values(ButtonIcon).includes(value);
                }
            },
            loading: {
                type: Boolean,
                default: false
            },
            block: {
                type: Boolean,
                default: false
            }
        },

        emits: {
            click () {
                return true;
            }
        },

        computed: {
            classes (): Record<string, boolean> {
                return {
                    button: true,
                    'p-button-outlined': this.variant !== ButtonVariant.primary,
                    'p-button-primary': this.variant === ButtonVariant.primary,
                    'p-button-secondary': this.variant === ButtonVariant.secondary,
                    'p-button-neutral': this.variant === ButtonVariant.neutral,
                    'p-button-text': this.variant === ButtonVariant.text,
                    'p-button-icon': !!this.icon,
                    ['p-button-icon--' + this.icon]: !!this.icon,
                    'p-button-icon-only': Boolean(this.icon && !this.label),
                    'p-button--loading': this.loading,
                    'p-button-xs': this.size === ButtonSize.xsmall,
                    'p-button-sm': this.size === ButtonSize.small,
                    'p-button-lg': this.size === ButtonSize.large,
                    'drivr-button-block': this.block
                };
            }
        }
    });


    import { defineComponent } from 'vue';
    import axios from 'axios';

    import UiInput from '@/components/input/Input.vue';
    import UiButton from '@/components/button/Button.vue';

    export default defineComponent({
        name: 'ForgetPasswordFormPage',
        components: { UiInput, UiButton },
        props: {
            token: {
                type: String,
                default: ''
            }
        },
        emits: {
            back () {
                return true;
            },
            success () {
                return true;
            }
        },
        data () {
            return {
                loading: false,
                password1: '',
                password2: ''
            };
        },
        mounted () {
            document.getElementById('password1')?.focus();
        },
        methods: {
            doRequest () {
                if (this.password1 !== this.password2) {
                    alert('Unfortunately, your input does not match.');
                    return;
                }

                this.loading = true;

                axios.request({
                    url: '/api/v1/identity/password-reset/confirmation',
                    method: 'post',
                    params: {
                        token: this.token,
                        password: this.password1
                    }
                })
                    .then(response => {
                        if (response.status === 404) {
                            alert('Sorry, token was not found. Please request a new token.');
                            this.$emit('back');
                        } else if (response.status !== 204) {
                            throw new Error('Unable to request reset: ' + response.status + ' ' + response.statusText);
                        } else {
                            this.$emit('success');
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line no-console
                        console.log(error);
                        alert(String(error));
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    });

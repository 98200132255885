import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_p_v_button = _resolveComponent("p-v-button")!

  return (_openBlock(), _createBlock(_component_p_v_button, {
    class: _normalizeClass(_ctx.classes),
    disabled: _ctx.disabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', $event)))
  }, {
    default: _withCtx(() => [
      (_ctx.$slots.default)
        ? _renderSlot(_ctx.$slots, "default", { key: 0 })
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ], 64))
    ]),
    _: 3
  }, 8, ["class", "disabled"]))
}

    import { defineComponent } from 'vue';
    import PVInputText from 'primevue/inputtext';

    export default defineComponent({
        name: 'UiInput',

        components: {
            PVInputText
        },

        props: {
            id: {
                type: String,
                required: false,
                default: null
            },
            type: {
                type: String,
                default: 'text',
                validator: function (value: string): boolean {
                    return ['text', 'email', 'password', 'number', 'search', 'tel', 'url'].includes(value);
                }
            },
            small: {
                type: Boolean,
                default: false
            },
            label: {
                type: String,
                required: false,
                default: null
            },
            placeholder: {
                type: String,
                required: false,
                default: null
            },
            name: {
                type: String,
                required: false,
                default: null
            },
            modelValue: {
                type: String,
                required: false,
                default: null
            },
            autocomplete: {
                type: String,
                required: false,
                default: undefined
            },
            autofocus: {
                type: Boolean,
                required: false,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            required: {
                type: Boolean,
                required: false
            }
        },

        emits: {
            'update:modelValue': (value: string) => {
                return value.length >= 0;
            }
        },

        methods: {
            onUpdate (value: string): void {
                this.$emit('update:modelValue', value);
            }
        }
    });

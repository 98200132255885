
    import { defineComponent } from 'vue';
    import UiButton from '@/components/button/Button.vue';

    export default defineComponent({
        name: 'ResetSuccessPage',
        components: { UiButton },
        emits: {
            back () {
                return true;
            }
        }
    });
